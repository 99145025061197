/*
 * 업무구분: 영업
 * 화 면 명: MSPFS301M
 * 화면설명: 고령자4305 조력자지정신청및조회
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>
<ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">
    <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->

    <!-- alert modal popup -->
    <fs-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>
    <!-- <div class="fts-header fts-pop">
      <ur-box-container alignV="center" direction="row" class="fts-flex-sb">
        <h2>고객성향 설문등록</h2>
        <mo-icon-button icon="close" class="btn-close"> 닫기 </mo-icon-button>
      </ur-box-container>
    </div> -->
    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left gap20">
            <div class="wrap-input row">
              <label class="emphasis"> 고객번호 </label>
              <mo-text-field
              numeric
                mask="#######"
                type='number'
              class="input-long w130"
              v-model.trim="ds_search.bfcer_cust_no.value"
              :class="ds_search.bfcer_cust_no.error ? 'error' : ''"
              @input="fn_CustInfo"
              ref="bfcer_cust_no"
              />
              <mo-button @click="fn_OpenPopup"> 고객정보 </mo-button>
              <mo-text-field  class="input-long w130"
              v-model="ds_search.bfcer_cust_nm" readonly/>
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button class="clear" @click="fn_init"> 초기화 </mo-button>
              <mo-button primary class="inquiry" @click="fn_searchList" :disabled="btn_searchList"> 조회 </mo-button>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title"> 조력자 지정 </h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <!-- <colgroup>
              <col width="20%">
              <col width="30%">
              <col width="20%">
              <col width="30%">
            </colgroup> -->
            <tbody>
              <tr>
                <th>
                  <span class="emphasis">조력자지정 여부</span>
                </th>
                <td colspan="3">
                  <div class="row">
                    <mo-radio-wrapper
                      :items="radio1"
                      v-model="coprt_peson_asgn_yn"
                      @input="fn_coprt_peson_asgn_yn_changed"
                      :class="coprt_peson_asgn_yn_error ? 'error' : ''"
                      ref="coprt_peson_asgn_yn"/>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span>조력자 미지정 사유</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :items="lv_commCode.code1050"
                      v-model="coprt_un_asgn_rsn_cd.value"
                      :disabled="coprt_un_asgn_rsn_cd.disabled"
                      placeholder=" "
                      ref="coprt_un_asgn_rsn_cd"
                      />
                  </div>
                </td>
                <th>
                  <span>기타사유 상세</span>
                </th>
                 <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      v-model="coprt_un_asgn_dtl_rsn"
                      :disabled="coprt_un_asgn_rsn_cd.value != '99'"
                      :class="coprt_un_asgn_rsn_cd.error ? 'error' : ''"
                      ref="coprt_un_asgn_dtl_rsn"
                      maxlength="150"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title"> 조력자 정보 </h2>
        </div>
        <div class="wrap-table">
          <!-- <colgroup>
            <col width="10%">
            <col width="15%">
            <col width="10%">
            <col width="*">
            <col width="10%">
            <col width="25%">
          </colgroup> -->
          <table class="table col-type w50p">
            <tbody>
              <tr class="wrap-input">
                <th>
                  <span class="emphasis">조력자명</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="w130"
                      v-model="coprt_peson_nm.value"
                      :disabled="coprt_peson_nm.disabled"
                      :class="coprt_peson_nm.error ? 'error' : ''"
                      ref="coprt_peson_nm"
                    />
                  </div>
                </td>
                <th class="bl-0">
                  <span class="emphasis">연락처</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :items="lv_commCode.code370"
                      v-model="coprt_peson_dstno_encr.value"
                      :disabled="coprt_peson_dstno_encr.disabled"
                      :class="coprt_peson_dstno_encr.error ? 'error' : ''"
                      placeholder=" "
                      class="w77"
                      ref="coprt_peson_dstno_encr"
                    />
                    <mo-text-field
                      class="w77"
                      v-model="coprt_peson_tlnum_no_encr.value"
                      :disabled="coprt_peson_tlnum_no_encr.disabled"
                      :class="coprt_peson_tlnum_no_encr.error ? 'error' : ''"
                      type="number"
                      maxlength="4"
                      ref="coprt_peson_tlnum_no_encr"
                     />
                    <mo-text-field
                      class="w77"
                      v-model="coprt_peson_bkno_sno_encr.value"
                      :disabled="coprt_peson_bkno_sno_encr.disabled"
                      :class="coprt_peson_bkno_sno_encr.error ? 'error' : ''"
                      type="number"
                      maxlength="4"
                      ref="coprt_peson_bkno_sno_encr"
                    />
                    <mo-button @click="fn_detail_Btn_cp" :disabled="btn_phone_del"> 삭제 </mo-button>
                  </div>
                </td>
                <th>
                  <span class="emphasis">본인과의 관계</span>
                </th>
                <td class="w167">
                  <div class="wrap-input row justify-end">
                    <mo-dropdown
                      class="w167"
                      :items="lv_commCode.code420"
                      v-model="coprt_peson_rltn_cd.value"
                      :disabled="coprt_peson_rltn_cd.disabled"
                      :class="coprt_peson_rltn_cd.error ? 'error' : ''"
                      placeholder=" "
                      ref="coprt_peson_rltn_cd"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <p class="mt-5 mb-1 font-bold">
          <mo-checkbox v-model="coprt_peson_info_cnsnt_yn" @input="fn_coprt_peson_info_cnsnt_yn_changed" :disabled="btn_peson_disabled">조력자 개인정보 수집에 대한 확인 여부</mo-checkbox>
        </p>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <!-- <colgroup>
              <col width="20%">
              <col width="30%">
              <col width="20%">
              <col width="30%">
            </colgroup> -->
            <tbody>
              <tr>
                <td colspan="4">
                  <p class="ml-4 colorG">고객님의 금융투자상품 투자와 관련하여 투자자보호를 위해 조력자에 대한 성명 및 연락처 정보 수집에 대한 동의</p>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis">녹취일자</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker
                      class="input-long"
                      v-model="coprt_peson_info_recd_ymd.value"
                      :disabled="coprt_peson_info_recd_ymd.disabled"
                      :class="coprt_peson_info_recd_ymd.error ? 'error' : ''"
                      :bottom="false"
                      ref="coprt_peson_info_recd_ymd"
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis">녹취시간</span>
                </th>
                 <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      numeric
                      class="input-long"
                      v-model="coprt_peson_info_recd_tm.value"
                      :disabled="coprt_peson_info_recd_tm.disabled"
                      :class="coprt_peson_info_recd_tm.error ? 'error' : ''"
                      placeholder="HH : MM : SS"
                      mask="##:##:##"
                      ref="coprt_peson_info_recd_tm"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <p class="mt-5 mb-1 font-bold">
          <mo-checkbox v-model="coprt_stab_proct_gudn_yn" @input="fn_coprt_stab_proct_gudn_yn_changed" :disabled="btn_peson_disabled">조력자 상품가입에 대한 사실 안내 여부</mo-checkbox>
        </p>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <!-- <colgroup>
              <col width="20%">
              <col width="30%">
              <col width="20%">
              <col width="30%">
            </colgroup> -->
            <tbody>
              <tr>
                <td colspan="4">
                  <p class="ml-4 colorG">상품가입에 대한 사실 안내</p>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis">녹취일자</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker
                      class="input-long"
                      v-model="coprt_stab_proct_recd_ymd.value"
                      :disabled="coprt_stab_proct_recd_ymd.disabled"
                      :class="coprt_stab_proct_recd_ymd.error ? 'error' : ''"
                      :bottom="false"
                      ref="coprt_stab_proct_recd_ymd"
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis">녹취시간</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      numeric
                      class="input-long"
                      v-model="coprt_stab_proct_recd_tm.value"
                      :disabled="coprt_stab_proct_recd_tm.disabled"
                      :class="coprt_stab_proct_recd_tm.error ? 'error' : ''"
                      placeholder="HH : MM : SS"
                      mask="##:##:##"
                      ref="coprt_stab_proct_recd_tm"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large"  :disabled="btn_delete" @click="fn_uid_btn_click('D')"> 삭제 </mo-button>
          <mo-button point size="large" :disabled="btn_update" @click="fn_uid_btn_click('U')"> 수정 </mo-button>
          <mo-button primary size="large" :disabled="btn_register" @click="fn_uid_btn_click('I')"> 등록 </mo-button>
          <mo-button primary size="large" v-if="lv_serverType==='local'" @click="fn_callBackIUD()"> 프로세스테스트 </mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- //.fts-main -->
    <!-- fs-cust-info start -->
      <fs-cust-info
        v-show="false"
        ref="fsCustInfo"
        :propObj="pCustInfoObj"
        @fs-cust-name="fn_SetEdCustName"
      ></fs-cust-info>
      <!-- fs-cust-info end -->
      <msp-fs-308p
      ref="popup308"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack308"
      :popupObj="pPopup308Obj"
    ></msp-fs-308p>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역
***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo' // 고객정보 (공통)
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil' // 고객정보 (공통)
import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil' // 고객정보 (공통)

import MSPFS308P from '~/src/ui/fs/MSPFS308P' // 고객정보 팝업

import moment from 'moment'

export default {
  /***********************************************************************************
     * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  name: 'MSPFS301M',
  screenId: 'MSPFS301M',
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'msp-fs-308p': MSPFS308P,
    'fs-cust-info': FSCustInfo
  },

  /***********************************************************************************
     * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created () {
    // 공통코드 세팅
    this.fn_SetCommCode()

    // if (!FSCommUtil.gfn_isNull(this.lv_isProcess)){

    // }
  },
  mounted () {
    this.$bizUtil.insSrnLog(this.$options.screenId)
    this.fn_init()

    console.log('mounted', this.$route.params.ed_cust_no)

    if (!FSCommUtil.gfn_isNull(this.$route.params.ed_cust_no)) {
      setTimeout(() => {
        this.ds_search.bfcer_cust_no.value = FSCommUtil.gfn_substr(this.$route.params.ed_cust_no, 0, 10)
        this.ds_search.bfcer_cust_nm = this.$route.params.Fnd_cust_nm
        // ㄴ고객정보 있으면 바로 기존 조력자 정보 조회
        this.fn_searchList()
      }, 100)
    }
  },
  /***********************************************************************************
     * Computed 함수 정의 영역
  ***********************************************************************************/
  computed: {
    radio1 () {
      let rtn = []
      rtn.push({value: 'Y', text: '예'})
      rtn.push({value: 'N', text: '아니오'})
      return rtn
    }
  },
  /***********************************************************************************
     * watch 함수 정의 영역
  ***********************************************************************************/
  watch: {
    'coprt_un_asgn_rsn_cd.value' (val) {
      if (val !== '99') {
        this.coprt_un_asgn_dtl_rsn = ''
      }
    }
  },
  /***********************************************************************************
     * 화면변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      // 공통 객체
      pHeaderObj: {
        title: '조력자지정 신청 및 조회',
        step: '7'
      },
      // 팝업 객체
      pAlertPopupObj: {},
      pPopup308Obj: {},
      // 고객정보
      pCustInfoObj: {},
      // param
      lv_isProcess: this.getStore('fsStore').getters.getState.isProcess,
      /***********************************************************************************
         * EAI_F10570117 : 개인고객정보조회
      ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
      // 고객검색 dataset
      ds_search: {
        proc_gb: '',
        bfcer_cust_no: {
          value: '',
          error: false
        }, // 고객번호
        bfcer_cust_nm: '' // 고객명
      },
      // 조력자검색 dataset
      ds_detail: {
        proc_gb: '', // 구분
        bfcer_cust_no: '', // 수익증권고객번호
        coprt_peson_reg_dtm: '', // 협조자등록일시
        coprt_peson_reg_fof_cd: '', // 협조자등록지점코드
        coprt_peson_regnt_eno: '', // 협조자등록자사번
        coprt_peson_asgn_yn: '', // 협조자지정여부
        coprt_peson_nm: '', // 협조자명
        coprt_peson_dstno_encr: '', // 협조자지역번호암호화
        coprt_peson_tlnum_no_encr: '', // 협조자국번번호암호화
        coprt_peson_bkno_sno_encr: '', // 협조자뒷자리일련번호암호화
        coprt_peson_rltn_cd: '', // 협조자관계코드
        coprt_peson_info_cnsnt_yn: 'N', // 협조자정보동의여부
        coprt_peson_info_recd_ymd: '', // 협조자정보녹취일자
        coprt_peson_info_recd_tm: '', // 협조자정보녹취시간
        coprt_stab_proct_gudn_yn: 'N', // 협조자상품안내여부
        coprt_stab_proct_recd_ymd: '', // 협조자상품녹취일자
        coprt_stab_proct_recd_tm: '', // 협조자상품녹취시간
        coprt_un_asgn_rsn_cd: '', // 협조자미지정사유코드
        coprt_un_asgn_dtl_rsn: '' // 협조자미지정상세사유
      },
      /***********************************************************************************
         * as-is 전역 변수
      ***********************************************************************************/
      dtl_rsn_max_size: 150, // 입력가능길이
      coprt_peson_asgn_yn: '', // 조력자 지정여부 예=Y 아니오=N
      coprt_un_asgn_rsn_cd: {
        value: '',
        disabled: true
      }, // 조력자 미지정 사유
      coprt_un_asgn_dtl_rsn: '', // 기타사유 상세
      coprt_peson_nm: {}, // 조력자명
      coprt_peson_dstno_encr: {
        value: '',
        disabled: false
      }, // 연락처1
      coprt_peson_tlnum_no_encr: {
        value: '',
        disabled: false
      }, // 연락처2
      coprt_peson_bkno_sno_encr: {
        value: '',
        disabled: false
      }, // 연락처3
      coprt_peson_rltn_cd: {
        value: '',
        disabled: false
      }, // 본인과의 관계
      coprt_peson_info_cnsnt_yn: 'N', // 조력자 개인정보 수집에 대한 확인 여부
      coprt_peson_info_recd_ymd: {
        value: '',
        disabled: true
      }, // 녹취일자
      coprt_peson_info_recd_tm: {
        value: '',
        disabled: true
      }, // 녹취시간
      coprt_stab_proct_gudn_yn: 'N', // 조력자 상품가입에 대한 사실 안내 여부
      coprt_stab_proct_recd_ymd: {
        value: '',
        disabled: true
      }, // 녹취일자
      coprt_stab_proct_recd_tm: {
        value: '',
        disabled: true
      }, // 녹취시간

      // btn disabled
      btn_delete: true, // 삭제버튼
      btn_update: true, // 수정버튼
      btn_register: true, // 등록버튼
      btn_searchList: true, // 조회버튼
      btn_init: false, // 초기화버튼
      btn_phone_del: false, // 연락처 삭제 버튼
      btn_peson_disabled : true, // 조력자 수집/사실안내여부

      lv_serverType: process.env.NODE_ENV,

      coprt_peson_asgn_yn_error: false,

      /***********************************************************************************
         * 공통코드
        ************************************************************************************/
      lv_commCode: {}
      // ds370 = 이동통신전화번호
      // ds420 = 본인과의관계
      // ds1050 = 조력자미지정사유
    }
  },
  /***********************************************************************************
     * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {

    /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
    ******************************************************************************/
    fn_SetCommCode () {
      console.log('fn_SetCommCode...')

      let params = [
        {'cId': '370', 'dayChkYn': 'N'},
        {'cId': '420', 'dayChkYn': 'N'},
        {'cId': '1050', 'dayChkYn': 'N'}
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },
    fn_SetCommCodeResult (pResult) {
      this.lv_commCode = pResult
    },

    /***************************************************************************************
       * Function명 : fn_init
       * 설명       : 초기화
      *****************************************************************************************/
    fn_init () {
      console.log('화면로드', this.lv_isProcess)
      // 고객검색 dataset
      this.ds_search = {
        proc_gb: '',
        bfcer_cust_no: {
          value: '',
          error: false
        }, // 고객번호
        bfcer_cust_nm: '' // 고객명
      }
      // 조력자검색 dataset
      this.ds_detail = {
        proc_gb: '', // 구분
        bfcer_cust_no: '', // 수익증권고객번호
        coprt_peson_reg_dtm: '', // 협조자등록일시
        coprt_peson_reg_fof_cd: '', // 협조자등록지점코드
        coprt_peson_regnt_eno: '', // 협조자등록자사번
        coprt_peson_asgn_yn: '', // 협조자지정여부
        coprt_peson_nm: '', // 협조자명
        coprt_peson_dstno_encr: '', // 협조자지역번호암호화
        coprt_peson_tlnum_no_encr: '', // 협조자국번번호암호화
        coprt_peson_bkno_sno_encr: '', // 협조자뒷자리일련번호암호화
        coprt_peson_rltn_cd: '', // 협조자관계코드
        coprt_peson_info_cnsnt_yn: 'N', // 협조자정보동의여부
        coprt_peson_info_recd_ymd: '', // 협조자정보녹취일자
        coprt_peson_info_recd_tm: '', // 협조자정보녹취시간
        coprt_stab_proct_gudn_yn: 'N', // 협조자상품안내여부
        coprt_stab_proct_recd_ymd: '', // 협조자상품녹취일자
        coprt_stab_proct_recd_tm: '', // 협조자상품녹취시간
        coprt_un_asgn_rsn_cd: '', // 협조자미지정사유코드
        coprt_un_asgn_dtl_rsn: '' // 협조자미지정상세사유
      }
      this.dtl_rsn_max_size = 150 // 입력가능길이
      this.coprt_peson_asgn_yn = '' // 조력자 지정여부 예=Y 아니오=N
      this.coprt_un_asgn_rsn_cd = {
        value: '',
        disabled: true
      } // 조력자 미지정 사유
      this.coprt_un_asgn_dtl_rsn = '' // 기타사유 상세
      this.coprt_peson_nm = {} // 조력자명
      this.coprt_peson_dstno_encr = {
        value: '',
        disabled: false,
        error: false
      } // 연락처1
      this.coprt_peson_tlnum_no_encr = {
        value: '',
        disabled: false,
        error: false
      } // 연락처2
      this.coprt_peson_bkno_sno_encr = {
        value: '',
        disabled: false,
        error: false
      } // 연락처3
      this.coprt_peson_rltn_cd = {
        value: '',
        disabled: false,
        error: false
      } // 본인과의 관계
      this.coprt_peson_info_cnsnt_yn = 'N' // 조력자 개인정보 수집에 대한 확인 여부
      this.coprt_peson_info_recd_ymd = {
        value: '',
        disabled: true,
        error: false
      } // 녹취일자
      this.coprt_peson_info_recd_tm = {
        value: '',
        disabled: true,
        error: false
      } // 녹취시간
      this.coprt_stab_proct_gudn_yn = 'N' // 조력자 상품가입에 대한 사실 안내 여부
      this.coprt_stab_proct_recd_ymd = {
        value: '',
        disabled: true,
        error: false
      } // 녹취일자
      this.coprt_stab_proct_recd_tm = {
        value: '',
        disabled: true,
        error: false
      } // 녹취시간

      // btn disabled
      this.btn_delete = true // 삭제버튼
      this.btn_update = true // 수정버튼
      this.btn_register = true // 등록버튼
      this.btn_searchList = true // 조회버튼
      this.btn_init = false // 초기화버튼
      this.btn_phone_del = false // 연락처 삭제 버튼
    },

    /***************************************************************************************
       * Function명 : fn_searchList
       * 설명       : 목록조회 (고객정보 있으면 바로 기존 조력자 정보 조회)
      *****************************************************************************************/
    fn_searchList () {
      this.ds_search.proc_gb = 'S'
      console.log('ds_search ======>', this.ds_search)
      if (FSCommUtil.gfn_isNull(this.ds_search.bfcer_cust_no.value)) {
        FSCommUtil.gfn_validate('고객번호를 입력하여 주십시요!')
        this.$refs['bfcer_cust_no'].focus()
        return
      }
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570176_S'
      this.eaiCommObj.params = {
        bfcer_cust_no: this.ds_search.bfcer_cust_no.value,
        bfcer_cust_nm: this.ds_search.bfcer_cust_nm,
        proc_gb: this.ds_search.proc_gb
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBackSelect)
    },

    /***************************************************************************************
       * Function명 : fn_uid_btn_click
       * 설명       : 등록 버튼 클릭 이벤트
    *****************************************************************************************/
    fn_uid_btn_click (flag) {
      let t_popupObj = {}

      switch (flag) {
        // 등록
        case 'I' :
          t_popupObj = {
            content: '등록하시겠습니까?',
            confirm: true,
            cancel: true,
            confirmFunc: this.fn_register
          }
          this.fn_AlertPopup(0, t_popupObj)
          break
          // 수정
        case 'U' :
          t_popupObj = {
            content: '수정하시겠습니까?',
            confirm: true,
            cancel: true,
            confirmFunc: this.fn_update
          }
          this.fn_AlertPopup(0, t_popupObj)
          break
          // 삭제
        case 'D' :
          t_popupObj = {
            content: '삭제하시겠습니까?',
            confirm: true,
            cancel: true,
            confirmFunc: this.fn_delete
          }
          this.fn_AlertPopup(0, t_popupObj)
          break
      }
    },
    /***************************************************************************************
       * Function명 : fn_register
       * 설명       : 등록 버튼 클릭 이벤트
    *****************************************************************************************/
    fn_register () {
      console.log('등록버튼클릭 this.ds_detail', this.ds_detail)
      if (!this.validation('I')) {
        return
      }

      // 서버에 호출 할 정보를 셋팅 해준다.
      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'txTSSFS43I1';
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570177_S'
      this.eaiCommObj.params = {
        bfcer_cust_no: this.ds_detail.bfcer_cust_no,
        coprt_peson_asgn_yn: this.ds_detail.coprt_peson_asgn_yn,
        coprt_peson_bkno_sno_encr: this.ds_detail.coprt_peson_bkno_sno_encr,
        coprt_peson_dstno_encr: this.ds_detail.coprt_peson_dstno_encr,
        coprt_peson_info_cnsnt_yn: this.ds_detail.coprt_peson_info_cnsnt_yn,
        coprt_peson_info_recd_tm: this.ds_detail.coprt_peson_info_recd_tm,
        coprt_peson_info_recd_ymd: this.ds_detail.coprt_peson_info_recd_ymd,
        coprt_peson_nm: this.ds_detail.coprt_peson_nm,
        coprt_peson_reg_dtm: this.ds_detail.coprt_peson_reg_dtm,
        coprt_peson_reg_fof_cd: this.ds_detail.coprt_peson_reg_fof_cd,
        coprt_peson_regnt_eno: this.ds_detail.coprt_peson_regnt_eno,
        coprt_peson_rltn_cd: this.ds_detail.coprt_peson_rltn_cd,
        coprt_peson_tlnum_no_encr: this.ds_detail.coprt_peson_tlnum_no_encr,
        coprt_stab_proct_gudn_yn: this.ds_detail.coprt_stab_proct_gudn_yn,
        coprt_stab_proct_recd_tm: this.ds_detail.coprt_stab_proct_recd_tm,
        coprt_stab_proct_recd_ymd: this.ds_detail.coprt_stab_proct_recd_ymd,
        coprt_un_asgn_dtl_rsn: this.ds_detail.coprt_un_asgn_dtl_rsn,
        coprt_un_asgn_rsn_cd: this.coprt_un_asgn_rsn_cd.value,
        proc_gb: this.ds_detail.proc_gb
      }// this.ds_detail;

      console.log('등록params', this.eaiCommObj.params)
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBackIUD)
    },

    /***************************************************************************************
       * Function명 : search_Ed_cust_cd_onchar
       * 설명       : 수정 버튼 클릭 이벤트
    *****************************************************************************************/
    fn_update () {
      // this.fn_enableComponent()
      this.btn_update = false
      this.btn_delete = false

      if (!this.validation('U')) {
        return
      }

      // 서버에 호출 할 정보를 셋팅 해준다.
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570178_S'
      this.eaiCommObj.params = {
        bfcer_cust_no: this.ds_detail.bfcer_cust_no,
        coprt_peson_asgn_yn: this.ds_detail.coprt_peson_asgn_yn,
        coprt_peson_bkno_sno_encr: this.ds_detail.coprt_peson_bkno_sno_encr,
        coprt_peson_dstno_encr: this.ds_detail.coprt_peson_dstno_encr,
        coprt_peson_info_cnsnt_yn: this.ds_detail.coprt_peson_info_cnsnt_yn,
        coprt_peson_info_recd_tm: this.ds_detail.coprt_peson_info_recd_tm,
        coprt_peson_info_recd_ymd: this.ds_detail.coprt_peson_info_recd_ymd,
        coprt_peson_nm: this.ds_detail.coprt_peson_nm,
        coprt_peson_reg_dtm: this.ds_detail.coprt_peson_reg_dtm,
        coprt_peson_reg_fof_cd: this.ds_detail.coprt_peson_reg_fof_cd,
        coprt_peson_regnt_eno: this.ds_detail.coprt_peson_regnt_eno,
        coprt_peson_rltn_cd: this.ds_detail.coprt_peson_rltn_cd,
        coprt_peson_tlnum_no_encr: this.ds_detail.coprt_peson_tlnum_no_encr,
        coprt_stab_proct_gudn_yn: this.ds_detail.coprt_stab_proct_gudn_yn,
        coprt_stab_proct_recd_tm: this.ds_detail.coprt_stab_proct_recd_tm,
        coprt_stab_proct_recd_ymd: this.ds_detail.coprt_stab_proct_recd_ymd,
        coprt_un_asgn_dtl_rsn: this.ds_detail.coprt_un_asgn_dtl_rsn,
        coprt_un_asgn_rsn_cd: this.coprt_un_asgn_rsn_cd.value,
        proc_gb: this.ds_detail.proc_gb
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBackIUD)
    },

    /***************************************************************************************
       * Function명 : search_Ed_cust_cd_onchar
       * 설명       : 삭제 버튼 클릭 이벤트
    *****************************************************************************************/
    fn_delete () {
      if (!this.validation('D')) {
        return
      }

      // 서버에 호출 할 정보를 셋팅 해준다.
      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'txTSSFS43D1';
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570179_S'
      this.eaiCommObj.params = {
        bfcer_cust_no: this.ds_detail.bfcer_cust_no,
        coprt_peson_asgn_yn: this.ds_detail.coprt_peson_asgn_yn,
        coprt_peson_bkno_sno_encr: this.ds_detail.coprt_peson_bkno_sno_encr,
        coprt_peson_dstno_encr: this.ds_detail.coprt_peson_dstno_encr,
        coprt_peson_info_cnsnt_yn: this.ds_detail.coprt_peson_info_cnsnt_yn,
        coprt_peson_info_recd_tm: this.ds_detail.coprt_peson_info_recd_tm,
        coprt_peson_info_recd_ymd: this.ds_detail.coprt_peson_info_recd_ymd,
        coprt_peson_nm: this.ds_detail.coprt_peson_nm,
        coprt_peson_reg_dtm: this.ds_detail.coprt_peson_reg_dtm,
        coprt_peson_reg_fof_cd: this.ds_detail.coprt_peson_reg_fof_cd,
        coprt_peson_regnt_eno: this.ds_detail.coprt_peson_regnt_eno,
        coprt_peson_rltn_cd: this.ds_detail.coprt_peson_rltn_cd,
        coprt_peson_tlnum_no_encr: this.ds_detail.coprt_peson_tlnum_no_encr,
        coprt_stab_proct_gudn_yn: this.ds_detail.coprt_stab_proct_gudn_yn,
        coprt_stab_proct_recd_tm: this.ds_detail.coprt_stab_proct_recd_tm,
        coprt_stab_proct_recd_ymd: this.ds_detail.coprt_stab_proct_recd_ymd,
        coprt_un_asgn_dtl_rsn: this.ds_detail.coprt_un_asgn_dtl_rsn,
        coprt_un_asgn_rsn_cd: this.coprt_un_asgn_rsn_cd.value,
        proc_gb: this.ds_detail.proc_gb
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBackIUD)
    },

    /***************************************************************************************
       * Function명 : search_Ed_cust_cd_onchar
       * 설명       : 화면 입력항목 유효성 검증
    *****************************************************************************************/
    validation (proc_gb) {
      //
      this.ds_detail.proc_gb = proc_gb
      this.ds_detail.bfcer_cust_no = this.ds_search.bfcer_cust_no.value
      this.ds_detail.coprt_peson_reg_dtm = '' // 협조자등록일시
      this.ds_detail.coprt_peson_reg_fof_cd = '' // 협조자등록지점코드
      this.ds_detail.coprt_peson_regnt_eno = '' // 협조자등록자사번
      this.ds_detail.coprt_peson_asgn_yn = this.coprt_peson_asgn_yn // 협조자지정여부
      this.ds_detail.coprt_peson_nm = this.coprt_peson_nm.value // 협조자명
      this.ds_detail.coprt_peson_dstno_encr = this.coprt_peson_dstno_encr.value // 협조자지역번호암호화
      this.ds_detail.coprt_peson_tlnum_no_encr = this.coprt_peson_tlnum_no_encr.value // 협조자국번번호암호화
      this.ds_detail.coprt_peson_bkno_sno_encr = this.coprt_peson_bkno_sno_encr.value // 협조자뒷자리일련번호암호화
      this.ds_detail.coprt_peson_rltn_cd = this.coprt_peson_rltn_cd.value // 협조자관계코드
      this.ds_detail.coprt_peson_info_cnsnt_yn = this.coprt_peson_info_cnsnt_yn // 협조자정보동의여부
      this.ds_detail.coprt_peson_info_recd_ymd = this.coprt_peson_info_recd_ymd.value !== '' ? moment(this.coprt_peson_info_recd_ymd.value).format('YYYYMMDD') : ''// 협조자정보녹취일자
      this.ds_detail.coprt_peson_info_recd_tm = this.coprt_peson_info_recd_tm.value // 협조자정보녹취시간
      this.ds_detail.coprt_stab_proct_gudn_yn = this.coprt_stab_proct_gudn_yn // 협조자상품안내여부
      this.ds_detail.coprt_stab_proct_recd_ymd = this.coprt_stab_proct_recd_ymd.value !== '' ? moment(this.coprt_stab_proct_recd_ymd.value).format('YYYYMMDD') : ''// 협조자상품녹취일자
      this.ds_detail.coprt_stab_proct_recd_tm = this.coprt_stab_proct_recd_tm.value // 협조자상품녹취시간
      this.ds_detail.coprt_un_asgn_rsn_cd = this.coprt_un_asgn_rsn_cd.value // 협조자미지정사유코드
      this.ds_detail.coprt_un_asgn_dtl_rsn = this.coprt_un_asgn_dtl_rsn // 협조자미지정상세사유

      if (FSCommUtil.gfn_isNull(this.ds_detail.bfcer_cust_no)) {
        FSCommUtil.gfn_validate(this, '고객번호를 입력하여 주십시요!')
        this.ds_search.bfcer_cust_no.error = true
        this.$refs['bfcer_cust_no'].focus()
        return false
      }
      if (FSCommUtil.gfn_isNull(this.ds_detail.coprt_peson_asgn_yn)) {
        FSCommUtil.gfn_validate(this, '조력자 지정여부를 선택하여 주십시요!')
        this.coprt_peson_asgn_yn_error = true
        this.$refs['coprt_peson_asgn_yn'].focus()
        return false
      }
      if (this.ds_detail.coprt_peson_asgn_yn === 'Y') {
        console.log('3', this.ds_detail)
        if (FSCommUtil.gfn_isNull(this.ds_detail.coprt_peson_nm)) {
          FSCommUtil.gfn_validate(this, '조력자명을 입력하여 주십시요!')
          this.$refs['coprt_peson_nm'].focus()
          this.coprt_peson_nm.error = true
          return false
        }
        if (FSCommUtil.gfn_isNull(this.ds_detail.coprt_peson_dstno_encr)) {
          FSCommUtil.gfn_validate(this, '지역번호를 입력하여 주십시요!')
          this.$refs['coprt_peson_dstno_encr'].focus()
          this.coprt_peson_dstno_encr.error = true
          return false
        }
        if (FSCommUtil.gfn_isNull(this.ds_detail.coprt_peson_tlnum_no_encr)) {
          FSCommUtil.gfn_validate(this, '국번번호를 입력하여 주십시요!')
          this.$refs['coprt_peson_tlnum_no_encr'].focus()
          this.coprt_peson_tlnum_no_encr.error = true
          return false
        }
        if (FSCommUtil.gfn_isNull(this.ds_detail.coprt_peson_bkno_sno_encr)) {
          FSCommUtil.gfn_validate(this, '뒷자리일련번호를 입력하여 주십시요!')
          this.$refs['coprt_peson_bkno_sno_encr'].focus()
          this.coprt_peson_bkno_sno_encr.error = true
          return false
        }
        if (FSCommUtil.gfn_isNull(this.ds_detail.coprt_peson_rltn_cd)) {
          FSCommUtil.gfn_validate(this, '본인과의 관계를 선택하여 주십시요!')
          this.$refs['coprt_peson_rltn_cd'].focus()
          this.coprt_peson_rltn_cd.error = true
          return false
        }
        if (this.ds_detail.coprt_peson_info_cnsnt_yn === 'Y') {
          if (this.ds_detail.coprt_peson_info_recd_ymd === '') {
            FSCommUtil.gfn_validate(this, '조력자 개인정보 수집에 대한 녹취일자를 입력하여 주십시요!')
            return
          }
          if (this.ds_detail.coprt_peson_info_recd_tm === '') {
            FSCommUtil.gfn_validate(this, '조력자 개인정보 수집에 대한 녹취시간을 입력하여 주십시요!')
            return
          }
        }
        if (this.ds_detail.coprt_stab_proct_gudn_yn === 'Y') {
          if (this.ds_detail.coprt_stab_proct_recd_ymd === '') {
            FSCommUtil.gfn_validate(this, '조력자 상품가입에 대한 녹취일자를 입력하여 주십시요!')
            return
          }
          if (this.ds_detail.coprt_stab_proct_recd_tm === '') {
            FSCommUtil.gfn_validate(this, '조력자 상품가입에 대한 녹취시간을 입력하여 주십시요!')
            return
          }
        }
      }

      /* 추가  2021.03.23.  ASR210200797_투자상품 판매 프로세스 강화 */
      if (proc_gb === 'I' || proc_gb === 'U') {
        // 등록 or 수정 시

        /* 조력자미지정사유 입력여부 체크 */
        if (this.ds_detail.coprt_peson_asgn_yn === 'N') {
          // 조력자 지정여부 = '아니오'
          if (FSCommUtil.gfn_isNull(this.coprt_un_asgn_rsn_cd.value)) {
            FSCommUtil.gfn_validate(this, '조력자미지정사유를 선택하여 주십시요!')
            this.$refs['coprt_un_asgn_rsn_cd'].focus()
            this.coprt_un_asgn_rsn_cd.error = true
            return false
          }

          /* 기타사유 인 경우 상세사유 입력 체크 */
          if (this.coprt_un_asgn_rsn_cd.value === '99') {
            var msg = this.ds_detail.coprt_un_asgn_dtl_rsn
            var msg_len = FSCommUtil.gfn_getByteLength3(msg)

            // 입력길이확인(입력내용없음)
            if (msg_len === 0) {
              FSCommUtil.gfn_validate(this, '기타사유인 경우 상세사유를 입력하여 주십시요!')
              this.$refs['coprt_un_asgn_dtl_rsn'].focus()
              this.coprt_un_asgn_dtl_rsn.error = true
              return false
            }

            // 입력길이확인(입력가능길이초과)
            if (msg_len > this.dtl_rsn_max_size) {
              FSCommUtil.gfn_validate(this, '입력값이 ' + (this.dtl_rsn_max_size / 3) + '자를 초과하였습니다.')
              this.$refs['coprt_un_asgn_dtl_rsn'].focus()
              this.coprt_un_asgn_dtl_rsn.error = true
              return false
            }
          }
        }
      }
      /* 조력자미지정사유 선택여부 체크 등 추가 End. */

      return true
    },
    /***************************************************************************************
       * Function명 : fn_callBackSelect fn_callBackIUD
       * 설명       : callback(고객정보 있으면 바로 기존 조력자 정보 조회)
    *****************************************************************************************/
    fn_callBackSelect (response) {
      console.log('조력자목록조회response', response)
      if (response.data.si430500_O_00VO === null || response.data.si430500_O_00VO.length === 0) { // 조회된 목록이 없을 때
        this.coprt_peson_info_recd_ymd.disabled = true
        this.coprt_peson_info_recd_tm.disabled = true
        this.coprt_stab_proct_recd_ymd.disabled = true
        this.coprt_stab_proct_recd_tm.disabled = true

        this.btn_register = false
        this.btn_update = true
        this.btn_delete = true

        this.coprt_peson_nm.disabled = false
        this.coprt_peson_dstno_encr.disabled = false
        this.coprt_peson_tlnum_no_encr.disabled = false
        this.coprt_peson_bkno_sno_encr.disabled = false
        this.coprt_un_asgn_rsn_cd.disabled = true // 추가 2021.03.23.  ASR210200797_투자상품 판매 프로세스 강화

        this.coprt_peson_asgn_yn = '' // 조력자 지정여부 예=Y 아니오=N
        this.coprt_un_asgn_rsn_cd = {
          value: '',
          disabled: true,
          error: false
        } // 조력자 미지정 사유
        this.coprt_un_asgn_dtl_rsn = '' // 기타사유 상세
        this.coprt_peson_nm = {} // 조력자명
        this.coprt_peson_dstno_encr = {
          value: '',
          disabled: false,
          error: false
        } // 연락처1
        this.coprt_peson_tlnum_no_encr = {
          value: '',
          disabled: false,
          error: false
        } // 연락처2
        this.coprt_peson_bkno_sno_encr = {
          value: '',
          disabled: false,
          error: false
        } // 연락처3
        this.coprt_peson_rltn_cd = {
          value: '',
          disabled: false,
          error: false
        } // 본인과의 관계
        this.coprt_peson_info_cnsnt_yn = 'N' // 조력자 개인정보 수집에 대한 확인 여부
        this.coprt_peson_info_recd_ymd = {
          value: '',
          disabled: true,
          error: false
        } // 녹취일자
        this.coprt_peson_info_recd_tm = {
          value: '',
          disabled: true,
          error: false
        } // 녹취시간
        this.coprt_stab_proct_gudn_yn = 'N' // 조력자 상품가입에 대한 사실 안내 여부
        this.coprt_stab_proct_recd_ymd = {
          value: '',
          disabled: true,
          error: false
        } // 녹취일자
        this.coprt_stab_proct_recd_tm = {
          value: '',
          disabled: true,
          error: false
        } // 녹취시간
      } else { // 기존 등록된 정보가 있을 때
        var data = response.data.si430500_O_00VO[0]
        this.coprt_peson_asgn_yn = data.coprt_peson_asgn_yn
        this.coprt_peson_bkno_sno_encr.value = data.coprt_peson_bkno_sno_encr
        this.coprt_peson_dstno_encr.value = data.coprt_peson_dstno_encr
        this.coprt_peson_info_cnsnt_yn = data.coprt_peson_info_cnsnt_yn === '' ? data.coprt_peson_asgn_yn : data.coprt_peson_info_cnsnt_yn
        this.coprt_peson_info_recd_tm.value = data.coprt_peson_info_recd_tm
        this.coprt_peson_info_recd_ymd.value = moment(data.coprt_peson_info_recd_ymd).format('YYYY-MM-DD')// 협조자상품녹취일자
        this.coprt_peson_nm.value = data.coprt_peson_nm
        this.coprt_peson_rltn_cd.value = data.coprt_peson_rltn_cd
        this.coprt_peson_tlnum_no_encr.value = data.coprt_peson_tlnum_no_encr
        this.coprt_stab_proct_gudn_yn = data.coprt_stab_proct_gudn_yn === '' ? data.coprt_peson_asgn_yn : data.coprt_stab_proct_gudn_yn
        this.coprt_stab_proct_recd_tm.value = data.coprt_stab_proct_recd_tm
        this.coprt_stab_proct_recd_ymd.value = moment(data.coprt_stab_proct_recd_ymd).format('YYYY-MM-DD')
        this.coprt_un_asgn_dtl_rsn = data.coprt_un_asgn_dtl_rsn
        this.coprt_un_asgn_rsn_cd.value = data.coprt_un_asgn_rsn_cd

        // 조력자 개인정보 수집에 대한 확인 여부 체크
        if (this.coprt_peson_info_cnsnt_yn === 'Y') {
          // 개인정보 녹취일자, 시간 활성화
          this.coprt_peson_info_recd_ymd.disabled = false
          this.coprt_peson_info_recd_tm.disabled = false
          // this.btn_peson_disabled = false
        }
        // 조력자 상품가입 안내여부 체크
        if (this.coprt_stab_proct_gudn_yn === 'Y') {
          // 일자, 시간 활성화
          this.coprt_stab_proct_recd_ymd.disabled = false
          this.coprt_stab_proct_recd_tm.disabled = false
          // this.btn_peson_disabled = false
        }
        // 조력자 지정 여부 '예'
        if (this.coprt_peson_asgn_yn === 'Y') {
          // 조력자명, 연락저 기입란, 관계선택란 활성화
          this.coprt_peson_nm.disabled = false
          this.coprt_peson_dstno_encr.disabled = false
          this.coprt_peson_tlnum_no_encr.disabled = false
          this.coprt_peson_bkno_sno_encr.disabled = false
          this.coprt_peson_rltn_cd.disabled = false
          // 미지정사유 기타사유상세 등 비활성화
          this.coprt_un_asgn_rsn_cd.disabled = true // 추가 2021.03.23.  ASR210200797_투자상품 판매 프로세스 강화
          this.btn_phone_del = false
          this.coprt_peson_info_recd_ymd.disabled = false
          this.coprt_peson_info_recd_tm.disabled = false
          this.coprt_stab_proct_recd_ymd.disabled = false
          this.coprt_stab_proct_recd_tm.disabled = false
        } else {
          // 조력자명, 연락처 기입란, 관계 등 disabled
          this.coprt_peson_nm.disabled = true
          this.coprt_peson_dstno_encr.disabled = true
          this.coprt_peson_tlnum_no_encr.disabled = true
          this.coprt_peson_bkno_sno_encr.disabled = true
          this.coprt_peson_rltn_cd.disabled = true
          // 미지정사유, 기타사유 enable
          this.coprt_un_asgn_rsn_cd.disabled = false // 추가 2021.03.23.  ASR210200797_투자상품 판매 프로세스 강화
          this.btn_phone_del = true
          this.coprt_peson_info_recd_ymd.disabled = true
          this.coprt_peson_info_recd_tm.disabled = true
          this.coprt_stab_proct_recd_ymd.disabled = true
          this.coprt_stab_proct_recd_tm.disabled = true
        }// else end

        // 등록 disabled, 수정,삭제,보고서 enable
        this.btn_register = true
        this.btn_update = false
        this.btn_delete = false
      }// else end
    },

    fn_callBackIUD (response) {
      console.log('등록,수정,삭제 response', response)

      // 정상 조회 = 809900, 데이터 없음 = 809990
      const lv_vm = this
      var t_msg = ''
      var t_data = ''
      var t_menu_id = this.lv_isProcess ? this.$route.params.menu_id : ''// 원래 타고 들어왔던 화면 명

      if (this.lv_isProcess) {
        switch (t_menu_id) {
          case 'MSPFS300M' :
            t_data = '그룹신규'
            break
          case 'MSPFS303M' :
            t_data = '연금계좌신규'
            break
          case 'MSPFS307M' :
            t_data = '계좌신규'
            break
        }
        if (response.data.error_msg === '809902' || response.data.error_msg === '809901' || response.data.error_msg === '809900') {
          this.fn_ReportPrint()
          if (this.eaiCommObj.commHeaderVO.eaiId === 'C391_F10570177_S') {
            t_msg = '등록'
          } else if (this.eaiCommObj.commHeaderVO.eaiId === 'C391_F10570178_S') {
            t_msg = '수정'
          }
          let t_popupObj = {
            content : t_msg + '이 완료되었습니다. ' + t_data + '가입을 계속 진행하겠습니까?',
            confirm : true,
            cancel : true,
            confirmFunc : function () {
              lv_vm.$router.push({
                name: t_menu_id,
                params: {
                  ed_cust_no: lv_vm.$route.params.ed_cust_no,
                  ed_group_no: lv_vm.$route.params.ed_group_no,
                  ed_insert: 'Y' 
                }
              })
            }
          }
          this.fn_AlertPopup(0, t_popupObj)
          this.fn_searchList()
        } else if (response.data.error_msg === '809903') {
          this.fn_searchList()
          let t_popupObj = {
            content: '삭제가 완료되었습니다. ' + t_data + ' 가입을 계속 진행하겠습니까?',
            confirm: true,
            cancel: true,
            confirmFunc: function () {
              lv_vm.$router.push({
                name: t_menu_id,
                params: {
                  ed_cust_no: lv_vm.$route.params.ed_cust_no,
                  ed_group_no: lv_vm.$route.params.ed_group_no
                }
              })
            }
          }
          this.fn_AlertPopup(0, t_popupObj)
        } else {
          let t_popupObj = {content : response.data.error_msg}
          this.fn_AlertPopup(0, t_popupObj)
        }
      } else { 
        //프로세스가 아닐때
        if (response.data.error_msg === '809902' || response.data.error_msg === '809901' || response.data.error_msg === '809900') {
          if (this.eaiCommObj.commHeaderVO.eaiId === 'C391_F10570177_S') {
            t_msg = '등록'
          } else if (this.eaiCommObj.commHeaderVO.eaiId === 'C391_F10570178_S') {
            t_msg = '수정'
          }
          let t_error_msg = FSMessageUtil.commMessage([])[response.data.error_msg]
          let lv_vm = this
          let t_popupObj = {
            confirm: true,
            confirmFunc :lv_vm.fn_ReportPrint,
            // contentTitle: '조력자 ' + t_msg + ' 완료',
            confirmData: {
              bfcer_cust_no : this.ds_search.bfcer_cust_no.value
            },
            content:  [t_error_msg],
            
          }
          this.fn_AlertPopup(9, t_popupObj)
          // this.fn_searchList()
        } else if (response.data.error_msg === "809903") {
          
          let t_popupObj = {content: FSMessageUtil.commMessage([])[response.data.error_msg]}
          this.fn_AlertPopup(0, t_popupObj)

          this.fn_searchList()
        } else {
          let t_popupObj = {content : response.data.error_msg}
          this.fn_AlertPopup(0, t_popupObj)
        }
      }//else end 
    },
    /******************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 전자서식 호출(조력자 수정, 등록시 )
    ******************************************************************************/
    async fn_ReportPrint () {
      console.log('fn_ReportPrint....')

      let formList = []
      let FS000028 = {formId: 'FS000028'}

      let searchData = {
        proc_gb: this.ds_detail.proc_gb, // 수익증권고객번호, Length : 7
        bfcer_cust_no: this.ds_search.bfcer_cust_no.value,
        bfcer_cust_nm: this.ds_search.bfcer_cust_nm
      }

      FS000028.params = {
        reportParam: JSON.stringify(searchData),
        reportUrl: 'reportRSI004305_Info'// 'reportRSI004305_Info',
      }

      formList.push(FS000028)
      
      console.log('searchData', searchData);
      FSInfoUtil.commReportInfo(this, formList)
    },
    /**********************************************************************************
       * Function명 : fn_enableComponent
       * 설명       : 버튼 활성,비활성 처리
    **********************************************************************************/
    fn_enableComponent () {
      this.btn_searchList = false
      this.btn_register = false
      this.btn_update = true
      this.btn_delete = true
      this.btn_init = false
    },

    /***************************************************************************************
       * Function명 : fn_detail_Btn_cp
       * 설명       : 연락처 입력 항목 삭제 버튼 클릭
    *****************************************************************************************/
    fn_detail_Btn_cp () {
      this.coprt_peson_tlnum_no_encr = {}
      this.coprt_peson_bkno_sno_encr = {}
    },

    /***************************************************************************************
       * Function명 : fn_coprt_stab_proct_gudn_yn_changed
       * 설명       : 조력자 상품가입에 대한 사실 안내 여부 변경시
    *****************************************************************************************/
    fn_coprt_stab_proct_gudn_yn_changed () {
      if (this.coprt_stab_proct_gudn_yn === 'Y') {
        this.coprt_stab_proct_recd_ymd.disabled = false
        this.coprt_stab_proct_recd_tm.disabled = false
      } else {
        this.coprt_stab_proct_recd_ymd.disabled = true
        this.coprt_stab_proct_recd_tm.disabled = true
        this.coprt_stab_proct_recd_ymd.value = ''
        this.coprt_stab_proct_recd_tm.value = ''
      }
    },

    /***************************************************************************************
       * Function명 : fn_coprt_peson_info_cnsnt_yn_changed
       * 설명       : 조력자 개인정보 수집에 대한 확인 여부 변경시
    *****************************************************************************************/
    fn_coprt_peson_info_cnsnt_yn_changed () {
      if (this.coprt_peson_info_cnsnt_yn === 'Y') {
        this.coprt_peson_info_recd_ymd.disabled = false
        this.coprt_peson_info_recd_tm.disabled = false
      } else {
        this.coprt_peson_info_recd_ymd.disabled = true
        this.coprt_peson_info_recd_tm.disabled = true
        this.coprt_peson_info_recd_ymd.value = ''
        this.coprt_peson_info_recd_tm.value = ''
      }
    },

    /***************************************************************************************
       * Function명 : fn_coprt_peson_asgn_yn_changed
       * 설명       : 조력자지정 여부 변경시
    *****************************************************************************************/
    fn_coprt_peson_asgn_yn_changed () {
      if (this.coprt_peson_asgn_yn === 'Y') {
        this.coprt_peson_nm.disabled = false // 조력자명
        this.coprt_peson_dstno_encr.disabled = false // 연락처1
        this.coprt_peson_tlnum_no_encr.disabled = false // 연락처2
        this.coprt_peson_bkno_sno_encr.disabled = false // 연락처3
        this.coprt_peson_rltn_cd.disabled = false // 본인과의 관계

        this.coprt_un_asgn_rsn_cd.disabled = true // 미지정사유
        this.coprt_un_asgn_rsn_cd.value = '' // 미지정사유
        // this.coprt_un_asgn_dtl_rsn.disabled = true; //기타사유 상세
        this.coprt_un_asgn_dtl_rsn = '' // 기타사유 상세

        this.btn_phone_del = false
        // this.btn_peson_disabled = false
        this.coprt_peson_info_cnsnt_yn = 'Y'
        this.coprt_stab_proct_gudn_yn = 'Y'
        if (this.coprt_peson_info_cnsnt_yn === 'Y') {
          this.coprt_peson_info_recd_ymd.disabled = false
          this.coprt_peson_info_recd_tm.disabled = false
        } else {
          this.coprt_peson_info_recd_ymd.disabled = true
          this.coprt_peson_info_recd_tm.disabled = true
        }

        if (this.coprt_stab_proct_gudn_yn === 'Y') {
          this.coprt_stab_proct_recd_ymd.disabled = false
          this.coprt_stab_proct_recd_tm.disabled = false
        } else {
          this.coprt_stab_proct_recd_ymd.disabled = true
          this.coprt_stab_proct_recd_tm.disabled = true
        }
      } else {
        this.coprt_peson_nm.disabled = true // 조력자명
        this.coprt_peson_dstno_encr.disabled = true // 연락처1
        this.coprt_peson_tlnum_no_encr.disabled = true // 연락처2
        this.coprt_peson_bkno_sno_encr.disabled = true // 연락처3
        this.coprt_peson_rltn_cd.disabled = true // 본인과의 관계

        this.coprt_peson_nm.value = '' // 조력자명
        this.coprt_peson_dstno_encr.value = '' // 연락처1
        this.coprt_peson_tlnum_no_encr.value = '' // 연락처2
        this.coprt_peson_bkno_sno_encr.value = '' // 연락처3
        this.coprt_peson_rltn_cd.value = '' // 본인과의 관계

        this.coprt_un_asgn_rsn_cd.disabled = false // 미지정사유
        this.coprt_un_asgn_dtl_rsn = '' // 기타사유 상세

        this.btn_phone_del = true // 연락처 삭제버튼
        // this.btn_peson_disabled = true
        this.coprt_peson_info_cnsnt_yn = 'N'
        this.coprt_stab_proct_gudn_yn = 'N'
        this.coprt_peson_info_recd_ymd.value = ''
        this.coprt_peson_info_recd_tm.value = ''
        this.coprt_stab_proct_recd_ymd.value = ''
        this.coprt_stab_proct_recd_tm.value = ''
        this.coprt_peson_info_recd_ymd.disabled = true
        this.coprt_peson_info_recd_tm.disabled = true
        this.coprt_stab_proct_recd_ymd.disabled = true
        this.coprt_stab_proct_recd_tm.disabled = true

        this.coprt_peson_nm.error = ''
        this.coprt_peson_dstno_encr.error = ''
        this.coprt_peson_tlnum_no_encr.error = ''
        this.coprt_peson_bkno_sno_encr.error = ''
        this.coprt_peson_rltn_cd.error = ''
      }
    },

    /******************************************************************************
       * Function명 : fn_CustInfo, fn_SetEdCustName
       * 설명       : 고객정보조회
    ******************************************************************************/
    fn_CustInfo () {
      console.log('fn_CustInfo.....')
      if (FSCommUtil.gfn_length(this.ds_search.bfcer_cust_no.value) < 7) {
        return
      }
      this.pCustInfoObj.custNo = FSCommUtil.gfn_substr(this.ds_search.bfcer_cust_no.value, 0, 7)
      this.$refs.fsCustInfo.fn_CustInfoSearch()
    },

    fn_SetEdCustName (val) {
      console.log('fn_setEdCustName', val)
      // 고객명
      this.ds_search.bfcer_cust_nm = val

      // 조회버튼 활성화
      this.btn_searchList = false

      if(val != ''){
        this.fn_searchList();

        // 조회 후, 고객명 있을 경우, 고객번호로 설정
        this.$bizUtil.fsUtils.selCustInfo(this, {bfcer_cust_no: this.pCustInfoObj.custNo})
      }

      
    },

    /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type) : 0: 우편번호 / 1: MDG/CDD정보비교 / 2: 고객정보 / 3: 직원번호(직원검색) / 4: 마케팅 동의여부 / 5: 고객ID선택
    ******************************************************************************/
    fn_OpenPopup () {
      console.log('팝업 오픈')
      this.pPopup308Obj = {
        bfcer_cust_no: this.ds_search.bfcer_cust_no.value,
        fnd_cust_nm: this.ds_search.bfcer_cust_nm
      }
      this.$refs.popup308.fn_Open()
    },

    async fn_Popup_CallBack308 (popupId, objRtn) {
      console.log('308 rtn ===', objRtn)
      if (FSCommUtil.gfn_isNull(objRtn)) {
        this.ds_search.bfcer_cust_no.value = ''
        this.ds_search.bfcer_cust_nm = ''
      } else {
        this.ds_search.bfcer_cust_no.value = objRtn // 수익증권고객번호
        this.fn_CustInfo()
      }
    },

    /******************************************************************************
       * Function명 : fn_historyBack
       * 설명       : 뒤로가기 버튼
    ******************************************************************************/
    fn_HistoryBack () {
      this.$router.go(-1)
    },


    /******************************************************************************
      * Function명 : fn_AlertPopup
      * 설명       : alert popup 호출(공통)
      ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      if( !FSCommUtil.gfn_isNull(pPopupObj) ) {

        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

  }// methods end

}
</script>
<style scoped>
</style>
